var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _vm.userDeptType === "all"
            ? _c(
                "div",
                { staticClass: "UserDeptTree" },
                [
                  _c("tree-layout", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: {
                      treeTitle: "组织架构",
                      treeLoading: _vm.treeLoading,
                      "tree-options": _vm.treeOptions,
                    },
                    on: {
                      "tree-node-click": _vm.getNodeClick,
                      "include-down": _vm.includeDownAll,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("CommonTreeNew", {
                    ref: "commonTree",
                    staticClass: "UserDeptTree",
                    attrs: {
                      defaultProps: _vm.defaultProps,
                      searchTitle: _vm.searchTitle,
                      treeTitle: _vm.$t(`cip.plat.sys.user.title.treeTitle`),
                      isShowdig: false,
                      showCheckbox: false,
                      defaultCheckedKeys: _vm.defaultCheckedKeys,
                      "popover-show": false,
                      urlParmas: {
                        tenantId: "",
                        deptCategory: "",
                        parentId: _vm.userInfo.deptId,
                        ..._vm.treeParams,
                      },
                    },
                    on: {
                      getNodeClick: _vm.getNodeClick,
                      "include-down": _vm.includeDown,
                      getTreeData: _vm.commonTreeData,
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchFrom",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayout",
                    staticClass: "UserDeptCrud",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      tableOptions: _vm.tableOptions,
                      tableData: _vm.tableData,
                      tableLoading: _vm.tableLoading,
                      searchColumns: _vm.searchColumns,
                      gridRowBtn: _vm.gridRowBtn,
                    },
                    on: {
                      "page-size-change": _vm.onLoad,
                      "page-current-change": _vm.onLoad,
                      "page-refresh-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "role-defBtn": _vm.roleDefBtn,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }